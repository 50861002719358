import css from 'bundle-text:./calendar.scss';

import calendarOptionsFromURL from './middelwares/calendarOptionsFromURL';
import i18n from './middelwares/i18n';
import seo from './middelwares/seo';
import page from './middelwares/page';

export const handler = [
	calendarOptionsFromURL(),
	i18n(),
	({ app }) => {

		const { from, to } = app;
	
		const range = from === to ? `${from}` : `${from}-${to}`;
		const duration = to - from + 1;

		const title = app.i18n.calendarPageTitle({ range, duration });
		const description = app.i18n.calendarPageDescription({ range, duration });

		return Object.assign(app, { title, description });
	},
	seo(),
	page('calendar'),
	({ app }) => {

		const theme = Array.from(app.themes).find(t => t.id === `theme:${app.theme}`);
		const themeStyle = theme.content.querySelector('style').textContent;
		const options = {
			// Calendar options
			loc:  app.loc,
			from: app.from,
			to:   app.to,
			// Theme options
			note: theme.dataset.note || 0,
		};

		// if (!theme) {
		// 	// TODO: Error handling
		// }

		app.page.innerHTML = `
			<style>${css}</style>
			<style>${themeStyle}</style>

			<header id="header">
				<h1 id="title">📆 ${app.seo.title}</h1>
				<nav>
					<ul>
						<li><a href="/">[Home]</a></li>
					</ul>
				</nav>
			</header>

			<multi-year-calendar
				id="calendar"
				loc="${options.loc}"
				from="${options.from}"
				to="${options.to}"
				note="${options.note}"
			></multi-year-calendar>
		`;

	}
];

export default {
	handler,
};
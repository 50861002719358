import html from 'bundle-text:./customizer.html';
import css from 'bundle-text:./customizer.scss';

class CalendarCustomizer extends HTMLElement {

	static observedAttributes = [
		'loc',
		'from',
		'to',
		'theme',
	];

	constructor() {

		super();

		this.form = null;
		this.about = null;

		this.floater = null;

		this.sharable = null;
		this.lastApplied = {
			loc:   null,
			from:  null,
			to:    null,
			theme: null,
		};

	}

	connectedCallback() {

		const shadow = this.attachShadow({ mode: 'open' });

		this.addEventListener('open', () => this.openCustomizer());
		this.addEventListener('close', () => this.closeCustomizer());
		this.addEventListener('toggle', () => this.toggleCustomizer());

		this.addEventListener('tips:ko', () => this.setAboutLang('ko'));
		this.addEventListener('tips:en', () => this.setAboutLang('en'));
		this.addEventListener('tips:ja', () => this.setAboutLang('ja'));

		// Init Floater
		this.floater = document.createElement('a');
		this.floater.setAttribute('id', 'floater');
		this.floater.setAttribute('href', 'customizer:toggle');
		this.floater.innerHTML = '⚒️ Calendar';

		// Init Customizer
		this.form = document.createElement('form');
		this.form.setAttribute('id', 'customizer');
		this.form.innerHTML = html;

		this.form.addEventListener('submit', e => {

			e.preventDefault();

			const detail = this.values();

			window.dispatchEvent(new CustomEvent('customizer:action:save', { detail }));

			this.setLastApplied(detail);
			this.updateSharableLink(detail);
		});

		// Style
		const style = document.createElement('style');

		style.textContent = css;

		// Theme Slot
		const themeSlot = document.createElement('slot');

		themeSlot.setAttribute('name', 'themes');

		shadow.appendChild(style);
		shadow.appendChild(themeSlot);
		shadow.appendChild(this.floater);
		shadow.appendChild(this.form);

		this.about = this.form.querySelector('#about');

		Array.from(this.form.querySelectorAll([
			'button',
			'[role="button"]',
		].join())).forEach(button => {
			button.addEventListener('click', e => {
				e.preventDefault();
				this.doButtonAction(e.target);
			});
		});

		[
			this.form.from,
			this.form.to,
		].forEach(input => {

			input.addEventListener('focus', e => {

				e.preventDefault();

				const current = this.values();

				const result = (prompt(
					``,
					`${current.from}-${current.to}`
				) || '').match(/^\s*(?<from>\d{1,4})[^\d]*(?<to>\d{1,4})?\s*$/);

				e.target.blur();

				if (!result) return; // Previous values will be left

				const { from: _from, to: _to = _from } = result.groups;

				const [from, to] = [_from, _to].map(v => parseInt(v)).sort(); // Sort and lower values will be "from" values.

				this.form.from.value = from;
				this.form.to.value = to;

				this.save();

			});
		});

		[
			this.form.loc,
			this.form.theme,
		].forEach(input => input.addEventListener('input', () => this.save()));

		Array.from(this.shadowRoot.querySelectorAll('a[sharable]')).forEach(sharable => {
			sharable.addEventListener('click', e => {
				e.preventDefault();
				this.selectAndDo(sharable);
			});
		});

		Array.from(this.shadowRoot.querySelectorAll('a[href^="customizer:"]')).forEach(a => {
			a.addEventListener('click', e => {
				e.preventDefault();
				this.dispatchEvent(new CustomEvent(e.target.href.replace('customizer:', ''), {}));
			});
		});

		this.openCustomizer();

		themeSlot.addEventListener('slotchange', () => {

			this.shadowRoot.querySelector('select[name="theme"]').innerHTML = Array.from(
				themeSlot.assignedNodes()[0].querySelectorAll('template')
			).map(template => ({
				id:       template.id.replace(/^theme:/, ''),
				name:     template.getAttribute('title'),
				template: template,
			})).map(({ id, name }) => `<option value="${id}">${name}</option>`).join('');

		});

		const timeoutId = setTimeout(() => {
			this.updateState();
			clearTimeout(timeoutId);
		}, 0);

	}

	attributeChangedCallback() {

		this.updateState();
	}

	isCustomizerOpen() {
		return this.form.getAttribute('aria-expanded') === 'true';
	}
	openCustomizer() {
		this.form.setAttribute('aria-expanded', true);
		return true;
	}
	closeCustomizer() {
		this.form.setAttribute('aria-expanded', false);
		return false;
	}
	toggleCustomizer() {
		return this.isCustomizerOpen() ?
			this.closeCustomizer() :
			this.openCustomizer()
	}

	normalize(values) {
		return {
			loc:   values.loc,
			from:  parseInt(values.from),
			to:    parseInt(values.to),
			theme: values.theme,
		};
	}
	values() {
		return this.normalize({
			loc:   this.form.loc.value,
			from:  this.form.from.value,
			to:    this.form.to.value,
			theme: this.form.theme.value,
		});
	}
	hasChanges() {

		const asIs = this.lastApplied;
		const toBe = this.values();

		return !(['loc', 'from', 'to', 'theme'].every(key => (
			(asIs[key] + '') === (toBe[key] + '')
		)));

	}
	isOkToSave() {
		const { loc, from, to, theme } = this.values();

		return (
			typeof loc === 'string' &&
			Number.isInteger(from) &&
			Number.isInteger(to) &&
			typeof theme === 'string'
		);
	}
	updateState() {

		if (!this.form) {
			return;
		}

		const defaultValues = this.normalize({
			loc:   this.getAttribute('loc'),
			from:  this.getAttribute('from'),
			to:    this.getAttribute('to'),
			theme: this.getAttribute('theme'),
		});

		this.setLastApplied(defaultValues);
		this.updateSharableLink(defaultValues);

		this.form.loc.value = defaultValues.loc;
		this.form.from.value = defaultValues.from;
		this.form.to.value = defaultValues.to;
		this.form.theme.value = defaultValues.theme;

		if (defaultValues.loc) {
			// Set tips language if there's a default value.
			this.setAboutLang(defaultValues.loc);
		}
	}
	save() {
		// Manually trigger submit event and save the calendar.
		if (!this.hasChanges() || !this.isOkToSave()) {
			// TODO: Error handling
			return;
		}
		this.form.requestSubmit();
	}
	setLastApplied(config) {

		return Object.assign(this.lastApplied, config);
	}
	updateSharableLink({ loc, from, to, theme }) {

		const url = [
			['loc', loc],
			['from', from],
			['to', to],
			['theme', theme],
		].reduce((link, [k, v]) => {
			return link.replace(`{${k}}`, v);
		}, this.getAttribute('sharable') || '');

		this.sharable = url;
	}

	setAboutLang(lang) {
		this.about.setAttribute('lang', lang)
	}

	doButtonAction(button) {

		const actionName = button.getAttribute('name');

		window.dispatchEvent(new CustomEvent('customizer:action', { detail: { name: actionName } }));

		const message = button.dataset.success;

		if (message) {

			const original = button.innerHTML;

			button.innerHTML = message;
			button.className = 'success';

			const timeoutId = setTimeout(() => {
				button.innerHTML = original;
				button.className = '';
				clearTimeout(timeoutId);
			}, 700);
		}

	}

	selectAndDo(el) {

		const selection = window.getSelection();

		selection.removeAllRanges();

		const range = document.createRange();

		range.selectNode(el);

		selection.addRange(range);

		return selection;

	}

}

customElements.define(`calendar-customizer`, CalendarCustomizer);
const metaDescription = document.querySelector('meta[name="description"]');
const ogTitle = document.querySelector('meta[property="og:title"]');
const ogDescription = document.querySelector('meta[property="og:description"]');

export default () => {

	return ({ app }) => {

		const {
			title = '',
			description = '',
		} = app;
	
		// Update basic SEO stuffs.
		document.title = title;
		metaDescription.setAttribute('content', description);
		
		// Update OpenGraph stuffs.
		ogTitle.setAttribute('content', title);;
		ogDescription.setAttribute('content', description);
	
		return Object.assign(app, { seo: { title, description } });
	};

}
export default {
	en: {
		landingPageTitle: d => `Multi Year Calendar | 10yearcalendar`,
		landingPageDescription: d => `Visualize your yearly plans with multi-year calendars. Print them and use them, or save them as PDF, Excel, or spreadsheet files.`,
		popularCalendars: d => `Popular Calendars`,
		calendarPageTitle: d => `${d.range} Calendar | ${d.duration} Year Planner`,
		calendarPageDescription: d => `Visualize your ${d.duration}-year plans with the ${d.range} calendar. Print it and use it, or save it as PDF, Excel, or spreadsheet files.`,
		calendarName: d => `${d.range} Calendar (${d.duration}-Year)`,
		makeOwnCalendar: d => `Make Calendar`,
	},
	ko: {
		landingPageTitle: d => `Multi Year Calendar | 10yearcalendar`,
		landingPageDescription: d => `앞으로의 여러해를 다년 캘린더로 계획하고 그려보세요. 프린트하여 사용하거나 PDF, 엑셀, 스프레드시트에서도 사용해보세요.`,
		popularCalendars: d => `인기 캘린더`,
		calendarPageTitle: d => `${d.range}년 캘린더 | ${d.duration}년 플래너`,
		calendarPageDescription: d => `${d.duration}년을 계획하고 그려볼 수 있는 ${d.range}년 캘린더. 프린트하여 사용하거나 PDF, 엑셀, 스프레드시트에서도 사용해보세요.`,
		calendarName: d => `${d.range}년 캘린더 (${d.duration}년)`,
		makeOwnCalendar: d => `캘린더 만들기`,
	},
	ja: {
		landingPageTitle: d => `Multi Year Calendar | 10yearcalendar`,
		landingPageDescription: d => `これからの数年間を多年カレンダーで計画して描きましょう。 印刷して使ったり、PDF、エクセル、スプレッドシートでも使えます。`,
		popularCalendars: d => `人気カレンダー`,
		calendarPageTitle: d => `${d.range}年カレンダー | ${d.duration}年プランナー`,
		calendarPageDescription: d => `${d.duration}年間を計画して描くことができる${d.range}年のカレンダー。 印刷して使ったり、PDF、エクセル、スプレッドシートでも使えます。`,
		calendarName: d => `${d.range}年カレンダー (${d.duration}年)`,
		makeOwnCalendar: d => `カレンダー作り`,
	},
};
import css from 'bundle-text:./home.scss';

import calendarOptionsFromURL from './middelwares/calendarOptionsFromURL';
import i18n from './middelwares/i18n';
import seo from './middelwares/seo';
import page from './middelwares/page';

export const handler = [
	calendarOptionsFromURL(),
	i18n(),
	({ app }) => {

		const title = app.i18n.landingPageTitle({});
		const description = app.i18n.landingPageDescription({});

		return Object.assign(app, { title, description });
	},
	seo(),
	page('home'),
	({ app }) => {

		const themeId = theme => theme.id.replace('theme:', '');
		const themeTitle = theme => theme.getAttribute('title');
		const themeDescription = theme => (
			theme.content.querySelector(`meta[name="theme:description"][lang="${app.loc}"]`)
				.getAttribute('content')
		);
		const themeStyle = theme => (
			theme.content.querySelector('style')
				.textContent
				.replace(/multi-year-calendar/g, `#calendar-${themeId(theme)}`)
		);

		const links = theme => [
			from => ({ from, to: from }),                                 // 1 Year
			from => ({ from, to: from + 2 }),                             // 3 Year
			from => ({ from, to: from + 4 }),                             // 5 Year
			from => ({ from, to: from + 9 }),                             // 10 Year
			from => ({ from, to: from + 14 }),                            // 15 Year
			from => ({ from, to: ((parseInt(from / 10) + 1) * 10) - 1 }), // ~End of Decade
		].map(fn => {

			const { from, to } = fn(app.from);

			const range = from === to ? `${from}` : `${from}-${to}`;
			const duration = to - from + 1;

			return { from, to, range, duration, theme };

		}).sort((a, b) => a.to - b.to); // Order ascending by 'to' values (since 'from' values are all the same for now)

		app.page.innerHTML = `
			<style>${css}</style>

			<hgroup>
				<h1>📆 ${app.title}</h1>
			</hgroup>

			<nav id="locales">
				<a href="/" lang="en">[English]</a>
				<a href="/ko" lang="ko">[한글]</a>
				<a href="/ja" lang="ja">[日本語]</a>
			</nav>
			${Array.from(app.themes).map(theme => `

			<section class="wrapper">

				<style>${themeStyle(theme)}</style>

				<section class="calendar-example">
					<multi-year-calendar
						id="calendar-${themeId(theme)}"
						class="calendar"
						loc="${app.loc}"
						from="${app.from}"
						to="${app.from + 2}"
						note="${theme.dataset.note || 0}"
					></multi-year-calendar>
				</section>

				<section class="calendar-description">
					<h2>${themeTitle(theme)}</h2>
					<p>${themeDescription(theme)}</p>
					<menu class="popular-links">${links(theme).map(link => (
						`<li><a href="/${app.loc}/${link.from}/${link.to}?theme=${themeId(link.theme)}">
							${app.i18n.calendarName({ range: link.range, duration: link.duration })}
						</a></li>`
					)).join('')}
					</menu>
					<a role="button" href="/${app.loc}/${app.from}/${app.to}?theme=${themeId(theme)}">
						📆 ${app.i18n.makeOwnCalendar()}
					</a>
				</section>

			</section>

			`).join('')}

		`;

	}
];

export default {
	handler,
};
import router from './src/lib/router.js';
import customizer from './src/lib/customizer.js';

import PageHome from './src/routes/home.js';
import PageCalendar from './src/routes/calendar.js';

if (process.env.NODE_ENV === 'production') {

	const adsense = document.createElement('script');
	adsense.setAttribute('async', '');
	adsense.setAttribute('crossorigin', 'anonymous');
	adsense.setAttribute('src', 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5772084038711393');

	const gtm = document.createElement('script');
	gtm.setAttribute('async', '');
	gtm.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-KCFDXXWGKX');

	document.head.appendChild(adsense);
	document.head.appendChild(gtm);

	window.dataLayer = window.dataLayer || [];
	window.gtag = function gtag(){dataLayer.push(arguments);}
	gtag('js', new Date());
	gtag('config', 'G-KCFDXXWGKX');

	// Track customizer actions (e.g., print, copy, link, ...)
	window.addEventListener('customizer:action', e => {
		gtag('event', e.type.replace(/:/g, '_'), e.detail);
	});
}

const page = document.querySelector('#page');
const themes = document.querySelectorAll('template[id^="theme:"]');

page.addEventListener('click', e => {

	const name = e.target.tagName
	const href = e.target.getAttribute('href');

	if (name !== 'A' || !href) {
		return;
	}

	if (!(href === '' || href.startsWith('/'))) {
		return;
	}

	e.preventDefault();

	return window.history.pushState({}, '', href);

});

customizer({
	calendarGetter: () => document.querySelector('#calendar'),
	sharableURL: `${process.env.APP_HOST}/{loc}/{from}/{to}?theme={theme}`,
	themes: themes,
	abouts: document.querySelectorAll('template[id^="contents:about:"]'),
});

router([
	{
		path: /^\/?$/,
		handler: PageHome.handler,
		app: {}
	},
	{
		path: /^\/(?<loc>[a-z]{2})$/,
		handler: PageHome.handler,
		app: {}
	},
	{
		path: /^\/(?<loc>[a-z]{2})\/(?<from>\d{4})$/,
		handler: PageCalendar.handler,
		app: { customizer: true }
	},
	{
		path: /^\/(?<loc>[a-z]{2})\/(?<from>\d{4})\/(?<to>\d{4})$/,
		handler: PageCalendar.handler,
		app: { customizer: true }
	},
], {
	app: {
		page:   page,
		themes: themes,
	}
});
export default ({
	calendarGetter,
	sharableURL,
	themes,
	abouts,
}) => {

	let customizer = null;

	const init = ({ loc, from, to, theme }) => {

		customizer = document.createElement('calendar-customizer');

		customizer.innerHTML = `
			${Array.from(abouts).map(about => (lang => `
			<div slot="about-${lang}" lang="${lang}">${about.innerHTML}</div>
			`)(about.id.replace(/^contents:about:/, ''))).join('')}
			<div slot="themes">${Array.from(themes).map(theme => theme.outerHTML).join('')}</div>
		`;

		customizer.setAttribute('sharable', sharableURL);

		document.body.appendChild(customizer);

	};

	const update = ({ loc, from, to, theme }) => {

		customizer.setAttribute('loc', loc);
		customizer.setAttribute('from', from);
		customizer.setAttribute('to', to);
		customizer.setAttribute('theme', theme);

	};

	window.addEventListener('customizer:action:save', e => {

		const detail = e.detail;
		const { loc, from, to, theme } = detail;

		const url = [
			['loc', loc],
			['from', from],
			['to', to],
			['theme', theme],
		].reduce((url, [k, v]) => url.replace(`{${k}}`, v), sharableURL);

		history.pushState(detail, '', url);
	});

	window.addEventListener('customizer:action', e => {

		switch (e.detail.name) {
			case 'print': {
				window.print();
				break;
			}

			case 'clipboard': {
				const selection = window.getSelection();

				selection.removeAllRanges();

				const range = document.createRange();

				range.selectNode(calendarGetter().shadowRoot.querySelector('table'));

				selection.addRange(range);

				document.execCommand('copy');

				selection.removeAllRanges();
				break;
			}

			case 'link': {
				navigator.clipboard.writeText(customizer.sharable);
				break;
			}
		}

	});

	window.addEventListener('router:afterhandler', e => {

		const detail = e.detail;

		if (detail.app.customizer) {

			if (!customizer) {
				init(detail.app);
			}

			update(detail.app);

			customizer.style.display = 'block';

		} else {

			if (customizer) {
				customizer.style.display = 'none';
			}

		}

	});

};
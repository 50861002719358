import css from 'bundle-text:./calendar.scss'

class MultiYearCalendar extends HTMLElement {

	static observedAttributes = [
		'loc',
		'from',
		'to',
		'note',
	];

	constructor() {

		super();

		this.wrapper = null;

		this.config = {
			loc:  null,
			from: null,
			to:   null,
			note: null,
		};

		this.locales = {
			en: {
				months: [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December"
				],
				weekdays: [
					"Sunday",
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday"
				]
			},
			ko: {
				months: [
					"1월",
					"2월",
					"3월",
					"4월",
					"5월",
					"6월",
					"7월",
					"8월",
					"9월",
					"10월",
					"11월",
					"12월"
				],
				weekdays: [
					"일",
					"월",
					"화",
					"수",
					"목",
					"금",
					"토"
				]
			},
			ja: {
				months: [
					"1月",
					"2月",
					"3月",
					"4月",
					"5月",
					"6月",
					"7月",
					"8月",
					"9月",
					"10月",
					"11月",
					"12月"
				],
				weekdays: [
					"日",
					"月",
					"火",
					"水",
					"木",
					"金",
					"土"
				]
			}
		};

	}

	connectedCallback() {

		const shadow = this.attachShadow({ mode: 'open' });

		this.wrapper = document.createElement('table');

		const style = document.createElement('style');

		style.textContent = css;

		shadow.appendChild(style);
		shadow.appendChild(this.wrapper);

		this.render();
	}

	attributeChangedCallback(name, oldValue, newValue) {

		this.render();
	}

	configure() {

		const loc = this.hasAttribute('loc') ? this.getAttribute('loc') : null;
		const from = this.hasAttribute('from') ? parseInt(this.getAttribute('from')) : null;
		const to = this.hasAttribute('to') ? parseInt(this.getAttribute('to')) : null;
		const note = this.hasAttribute('note') ? parseInt(this.getAttribute('note')) : 0;

		this.config.loc = loc;
		this.config.from = from;
		this.config.to = to;
		this.config.note = note;

		return this.config;

	}

	render() {

		const { loc, from, to, note } = this.configure();

		if (!(loc && from && to)) {
			return;
		}

		if (!this.wrapper) {
			return;
		}

		const range = to - from + 1;

		// Fix some locale & language stuffs.
		this.wrapper.setAttribute('part', `calendar locale-${loc}`);
		this.wrapper.setAttribute('cellspacing', '0');
		this.wrapper.setAttribute('lang', loc);

		const locObject = this.locales[loc] || this.locales.en;

		const allYears = Array.from(new Array(range)).map((v, i) => {

			const year = from + i;

			return {
				value: year,
				month: Array.from(new Array(12)).map((v, i) => {

					const month = i + 1;

					const dayOf1st = new Date(year, i, 1).getDay();
					const daysInMonth = new Date(year, month, 0).getDate();

					return {
						name: locObject.months[i],
						value: month,
						dateMap: Array.from(new Array(daysInMonth)).reduce((o, v, i) => Object.assign(o, {
							[dayOf1st + i]: i + 1,
						}), {}),
						dayOf1st: dayOf1st,
						daysInMonth: daysInMonth,
					};

				}),
			};

		});

		const allDaysOfMonth = Array.from(new Array(37)).map((v, i) => {

			const value = i % 7;

			return {
				name: locObject.weekdays[value].slice(0, 2),
				value: value,
				type: Number.isInteger(value / 6) ? 'weekend' : 'weekday'
			};

		});

		const p0 = v => ('0' + v).slice(-2)

		this.wrapper.innerHTML = allYears.map(year => `
			<tbody part="year-calendar calendar-${year.value}">
				<tr part="year-header">
					<th part="year year-${year.value}">${year.value}</th>
					${allDaysOfMonth.map(day => `
					<th part="day day-${day.value} ${day.type}">${day.name}</th>`
					).join('')}
				</tr>
				${year.month.map(month => `
				<tr part="month-calendar calendar-${year.value}-${p0(month.value)}">
					<th part="month month-${p0(month.value)}">${month.name}</th>
					${allDaysOfMonth.map((day, i) => `
					<td part="date day-${day.value} ${day.type} date-${year.value}-${p0(month.value)}-${p0(month.dateMap[i] || 0)} date-${month.dateMap[i] ? 'filled' : 'empty'}">${month.dateMap[i] || ''}</td>`
					).join('')}
				</tr>
				${note ? `
				<tr part="month-notes notes-${year.value}-${p0(month.value)}">
					<th part="note month-note note-${year.value}-${p0(month.value)}"></th>
					${allDaysOfMonth.map((day, i) => `
					<td part="note date-note note-${year.value}-${p0(month.value)}-${p0(month.dateMap[i] || 0)}"></td>
					`).join('')}
				</tr>` : ''}
				`).join('')}
			</tbody>
		`.trim()).join('').trim();

	}

}

customElements.define(`multi-year-calendar`, MultiYearCalendar);
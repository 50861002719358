import i18n from '/src/lib/i18n.js';

export default () => {

	return ({ app }) => {

		app.i18n = i18n[app.loc || 'en'];

	};

};
export default function router(routes = [], config = {}) {

	const wrap = type => {

		const orig = history[type];

		return function() {

			const rv = orig.apply(this, arguments);

			window.dispatchEvent(new CustomEvent(type.toLocaleLowerCase(), { detail: {} }));

			return rv;
		};
	};

	history.pushState = wrap('pushState');
	history.replaceState = wrap('replaceState');

	const handle = async () => {
		
		for (const r of routes) {

			const { path, handler } = r;

			const found = (location.pathname || '').replace(/\/$/, '').match(path);

			if (found) {

				const detail = {
					params: found.groups || {},
					query: location.search.replace(/^\?/, '').split('&').reduce((o, seg) => {
						const [key, value] = seg.split('=');
						return {
							...o,
							[key]: value,
						};
					}, {}),
					app: {
						...(config.app || {}),
						...(r.app || {}),
					},
					route: r,
				};

				window.dispatchEvent(new CustomEvent('router:beforehandler', { detail }));

				for (const h of handler) {
					await h(detail);
				}

				window.dispatchEvent(new CustomEvent('router:afterhandler', { detail }));

				return;
			}

		}
		
	};

	handle(); // Run manually for the first tiem.

	['pushstate', 'replacestate', 'popstate'].forEach(eventName => {
		window.addEventListener(eventName, handle);
	});

}
export default () => {

	return ({ params, query, app }) => {

		const { loc: _loc, from: _from, to: _to } = params;
		const { theme: _theme } = query; 
	
		const now = new Date();
		const currentMonth = now.getMonth();
		const currentYear = now.getFullYear();
	
		const preferredStartYear = (
			currentMonth >= 11 ?  // If it's December...
				currentYear + 1 : // Set the start year as next year.
				currentYear
		);
	
		const loc = _loc || 'en';
		const from = _from ? parseInt(_from) : preferredStartYear;
		const to = _to ? parseInt(_to) : (_from ? from : preferredStartYear + 9);
		const theme = _theme || app.themes && (
			Array.from(app.themes)
				.find(t => t.hasAttribute('default'))
				.id.replace(/^theme:/, '')
		);

		return Object.assign(app, { loc, from, to, theme });
	}

};